import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import TemplateAddForm from "../../components/templateForm"
import { TemplateFormFields } from "./templateFormFields.jsx"
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { supportFonts, templateAlignChoices, templateESLModelChoices, templateTypeChoices } from "../../selectfieldchoices/shelflabelselect.mjs";
import { defaultTemplate, templateElementDefault } from "../../defaultformvalues/templatedefaults.mjs";
import { setTemplateElementModalShow } from "../../redux/dataSlices/templateSlice.js";
import { getSettingsSchapkaartjes } from "../../redux/dataSlices/settingsSlice.js";
import { getHashPrintersAll } from "../../redux/dataSlices/printerSlice.js";
import { setLoading } from "../../redux/dataSlices/loadingSlice.js";
import PostTemplate from "../../actablueAPI/shelflabels/PostTemplate.jsx";
import useHandleError from "../../customhooks/useHandleError.jsx";

const TemplateAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const schapSettings = useSelector(getSettingsSchapkaartjes);
  const hash_printers = useSelector(getHashPrintersAll);
  const dpi = hash_printers[schapSettings?.default_printer]?.dpi ?? 300;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const [template, setTemplate] = useState({ 'admin_id': admin_id, 'location_id': location_id, ...defaultTemplate })
  const [selectedElement, setSelectedElement] = useState(null);
  const [selectedElementIndex, setSelectedElementIndex] = useState(null);
  const [templateImages, setTemplateImages] = useState(Array)
  const navTitle = "Template add"

  const onChange = (event) => {
    let newTemplate = HandleOnChange({ event: event, object: template })
    setTemplate(newTemplate)
  }

  const onChangeMmToPx = (event) => {
    const pixels = Math.round((dpi * event?.target?.value) / 25.4);
    let newTemplate = HandleOnChange({ event: event, object: template })
    if (event?.target?.id === 'height_mm') newTemplate.height_px = pixels;
    if (event?.target?.id === 'width_mm') newTemplate.width_px = pixels;
    setTemplate(newTemplate)
  }

  const onChangePxToMm = (event) => {
    const mm = Math.round((event?.target?.value * 25.4) / dpi);
    let newTemplate = HandleOnChange({ event: event, object: template })
    if (event?.target?.id === 'height_px') newTemplate.height_mm = mm;
    if (event?.target?.id === 'width_px') newTemplate.width_mm = mm;
    setTemplate(newTemplate)
  }

  const onTypeChange = (event) => {
    let newTemplate = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: template, selectfield: 'type' })
    setTemplate(newTemplate)
  }

  const onEslChange = (event) => {
    let newTemplate = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: template, selectfield: 'esl_model' })
    setTemplate(newTemplate)
  }

  const onAlignChange = (event) => {
    let newTemplate = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: template, selectfield: 'text_align' })
    setTemplate(newTemplate)
  }

  const onFontChange = (event) => {
    let newTemplate = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: template, selectfield: event.id })
    setTemplate(newTemplate)
  }

  const onAddElementClick = (event) => {
    event.preventDefault();

    let newTemplate = JSON.parse(JSON.stringify(template))

    newTemplate?.elements.push(templateElementDefault)

    let index = newTemplate?.elements.indexOf(templateElementDefault)

    setTemplate(newTemplate)

    setSelectedElementIndex(index);
    dispatch(setTemplateElementModalShow(true));
  }

  const onRemoveElementClick = (event, element) => {
    event.stopPropagation();
    event.preventDefault();
    let newTemplate = JSON.parse(JSON.stringify(template))

    let index = template?.elements.indexOf(element)
    if (index > -1) {
      newTemplate?.elements.splice(index, 1)

      setTemplate(newTemplate)
    }
  }

  const onElementClick = (event, element, index) => {
    event.preventDefault();

    if (selectedElement === element) {
      dispatch(setTemplateElementModalShow(false));
      setSelectedElement(null);
      setSelectedElementIndex(null);
    } else {
      setSelectedElement(element);
      setSelectedElementIndex(index);
      dispatch(setTemplateElementModalShow(true));
    }
  }

  const onModalHide = () => {
    dispatch(setTemplateElementModalShow(false));
    setSelectedElement(null);
  }

  const onUploadImage = (event) => {
    let newTemplateImages = JSON.parse(JSON.stringify(templateImages))

    if (event.target.files.length !== 1) return
    
    const file = event.target.files[0];
    const filename = file.name
    const filesize = file.size
    const filetype = file.type

    if (templateImages.find(image => image?.filename === filename)) return

    const reader = new FileReader();

    reader.onloadend = (event) => {
      const base64String = reader.result;
      const img = new Image();
      img.src = event.target.result;
      
      img.onload = function() {
          newTemplateImages.push({
            "admin_id": template?.admin_id,
            "location_id": template?.location_id,
            "filename": filename,
            "filetype": `.${filetype.split('/')[1]}`,
            "filesize": filesize,
            "data": base64String,
            "height_px": this.width,
            "width_px": this.height,
            "image_x": 0,
            "image_y": 0,
            "creation": moment(),
            "modification": moment()
          })
    
          setTemplateImages(newTemplateImages)
      };
    };

    if (file) {
      reader.readAsDataURL(file);
    }

  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    dispatch(setLoading(true))
    template.images = templateImages
    template.admin_id = admin_id
    template.location_id = location_id

    PostTemplate({token:token, data:template})
    .then(() => {
      navigate('/shelflabels')
    })
    .catch(error => {
      HandleError({ error: error })
      navigate('/shelflabels')
    });
};

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: TemplateFormFields(),
    field: {
      onChange: onChange
    },
    draggable: Boolean(true),
    isPrint: Boolean(false),
    data: template,
    template_data: {
      setTemplate: setTemplate
    },
    images: {
      templateImages: templateImages,
      setTemplateImages: setTemplateImages,
      onUpload: onUploadImage
    },
    printer_dpi: dpi,
    elements: {
      selected: selectedElement,
      selectedIndex: selectedElementIndex,
      onClick: onElementClick,
      onAddClick: onAddElementClick,
      onRemoveClick: onRemoveElementClick,
      onModalHide: onModalHide
    },
    type: {
      name: 'type',
      options: templateTypeChoices,
      selected: {},
      onChange: onTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(false)
    },
    esl_model: {
      name: 'esl_model',
      options: templateESLModelChoices,
      selected: {},
      onChange: onEslChange,
      clearable: Boolean(false),
      searchable: Boolean(false)
    },
    text_align: {
      name: 'text_align',
      options: templateAlignChoices,
      selected: {},
      onChange: onAlignChange,
      clearable: Boolean(false),
      searchable: Boolean(false)
    },
    height_mm: {
      onChange: onChangeMmToPx
    },
    width_mm: {
      onChange: onChangeMmToPx
    },
    height_px: {
      onChange: onChangePxToMm
    },
    width_px: {
      onChange: onChangePxToMm
    },
    title_text_font: {
      name: 'title_text_font',
      options: supportFonts,
      selected: [],
      onChange: onFontChange,
      clearable: Boolean(false),
      searchable: Boolean(false)
    },
    contents_text_font: {
      name: 'contents_text_font',
      options: supportFonts,
      selected: [],
      onChange: onFontChange,
      clearable: Boolean(false),
      searchable: Boolean(false)
    },
    price_text_font: {
      name: 'price_text_font',
      options: supportFonts,
      selected: [],
      onChange: onFontChange,
      clearable: Boolean(false),
      searchable: Boolean(false)
    }
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    let newTemplate = JSON.parse(JSON.stringify(template))

    newTemplate.height_mm = Math.round((template?.height_px * 25.4) / dpi);
    newTemplate.width_mm = Math.round((template?.width_px * 25.4) / dpi);

    setTemplate(newTemplate)
    // eslint-disable-next-line
  }, [dpi]);

  const templatesCard = {
    size: 12,
    title: navTitle,
    data: <TemplateAddForm {...formData} />,
  };


  return (
    <>
      <BasicCard {...templatesCard} />
    </>
  );
};
export default TemplateAdd;
