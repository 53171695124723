import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import Timeline, { TimelineHeaders, SidebarHeader, DateHeader, TimelineMarkers, TodayMarker } from 'react-calendar-timeline';
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container'
import reservationTimelineItemRenderer from './reservationTimeLineItemRenderer';
import 'react-calendar-timeline/lib/Timeline.css';
import './css/reservationTimeLine.css'
import ReservationTimelineModal from './reservationTimelineModal';
import ReservationButtons from "../portal/reservations/reservationbuttons";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../redux/dataSlices/tokenSlice";
import { setReservationsHighLights, setReservationObjects, getStartDate, setStartDate, getEndDate, setEndDate, setReservations, getReservations, getReservationLocation } from '../redux/dataSlices/reservationSlice';
import { getSelectedAdmin_id } from "../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from '../redux/dataSlices/selectLocationSlice';
import { setLoading } from '../redux/dataSlices/loadingSlice';
import ReservationHighLightCalculation from '../portal/reservations/highlightcalculation';
import GetReservationsListByLocationId from '../actablueAPI/reservations/GetReservationsListByLocationId';
import PutReservation from '../actablueAPI/reservations/PutReservation';
import GetReservationObjects from '../actablueAPI/reservations/GetReservationObjects';
import useHandleError from '../customhooks/useHandleError';

export default function ReservationTimeline() {
  const websiteUrl = window.location.href;
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const reservation_location = useSelector(getReservationLocation)
  const reservations = useSelector(getReservations);
  const startDate = moment(useSelector(getStartDate), 'YYYY-MM-DD:HH:mm:ssZZ')
  const endDate = moment(useSelector(getEndDate), 'YYYY-MM-DD:HH:mm:ssZZ')
  const dispatch = useDispatch()
  const HandleError = useHandleError();
  const [items, setItems] = useState([]);
  const buttons = ReservationButtons.call();
  const [groups, setGroups] = useState([])
  const timeSteps = {
    minute: 15,
    hour: 1,
    day: 1,
    month: 1,
    year: 1
  }

  let bgColor = '#000000';
  switch (true) {
    case websiteUrl.includes('humbee'):
      bgColor = '#4D7D6B';
      break;
    case websiteUrl.includes('actablue'):
    case websiteUrl.includes('octoblue'):
      bgColor = '#017AB5';
      break;
    default:
      bgColor = '#017AB5';
      break;
  }

  function getReservationsWithTime(start, end, location_id) {
    GetReservationsListByLocationId({ token: token, admin_id: admin_id, location_id: location_id, start: start, end: end })
      .then(response => {
        setItems([])
        dispatch(setReservations(response.data))
        dispatch(setReservationsHighLights(ReservationHighLightCalculation(response.data)))
      })
  }

  const timelineModalView = buttons.hasOwnProperty('timelineModalView') ? Object.assign({}, buttons.timelineModalView) : false;

  const reservationUpdate = (reservation) => {
    reservation.type = reservation.type.toUpperCase()
    reservation.status = reservation.status.toUpperCase()

    // reservation.reservation_timestamp = moment(reservation.reservation_timestamp).format('YYYY-MM-DDTHH:mm:ss.000+00:00');
    reservation.reservation_timestamp = moment(reservation.reservation_timestamp).utc().format();

    PutReservation({ token: token, data: reservation })
      .then(() => { })
      .catch(error => {
        HandleError({ error: error, variant: 'error', anchorOrigin: { horizontal: 'center', vertical: 'bottom' } })
      })
  };

  const handleItemMove = (itemId, dragTime, newGroupOrder) => {
    let reservation;
    const updatedItems = items.map(item => {
      if (item.id === itemId) {
        const oldGroup = item.group
        const group = Object.assign({}, groups[newGroupOrder]);
        const itemDuration = item.end_time.diff(item.start_time, 'minute');
        let newReservationObjects = [...item.objectData.reservation_objects];

        let oldGroupIndex = newReservationObjects.findIndex(object => object.reservation_object_id === oldGroup);

        if (oldGroupIndex !== -1) {
          newReservationObjects.splice(oldGroupIndex, 1, group.objectData);
          newReservationObjects[oldGroupIndex] = Object.assign({}, newReservationObjects[oldGroupIndex], { reservation_object_id: newReservationObjects[oldGroupIndex].id })
          delete newReservationObjects[oldGroupIndex].id
        }

        const updatedObjectData = {
          ...item.objectData,
          reservation_timestamp: moment(dragTime).format(),
          reservation_objects: newReservationObjects
        };

        const updated = {
          ...item,
          start_time: moment(dragTime),
          end_time: moment(dragTime).add(itemDuration, 'minute'),
          group: group.id,
          objectData: updatedObjectData,
        };

        reservation = updated.objectData;
        return updated;
      }
      return item;
    });

    setItems(updatedItems);

    if (reservation) {
      reservationUpdate(reservation)
    }

  };


  const handleItemResize = (itemId, time, edge) => {
    let reservation;

    const updatedItems = items.map(item => {
      if (item.id === itemId) {
        const startMoment = edge === 'left' ? moment(time) : item.start_time;
        const endMoment = edge === 'left' ? item.end_time : moment(time);
        const durationMinutes = endMoment.diff(startMoment, 'minutes');

        const updatedObjectData = {
          ...item.objectData,
          duration: durationMinutes,
          reservation_timestamp: startMoment.format(),
        };

        const updated = {
          ...item,
          start_time: edge === 'left' ? moment(time) : item.start_time,
          end_time: edge === 'left' ? item.end_time : moment(time),
          objectData: updatedObjectData,
        };

        reservation = updated.objectData;
        return updated;
      }
      return item;
    });

    setItems(updatedItems);

    if (reservation) {
      reservationUpdate(reservation)
    }
  };

  const handleItemDoubleClick = (itemId) => {
    let item = items.find(item => item.id === itemId);
    timelineModalView.onClick(item.objectData);
  };

  const handleZoom = (event) => {
    dispatch(setStartDate(moment(event.canvasTimeStart).format('YYYY-MM-DD:HH:mm:ssZZ')))
    dispatch(setEndDate(moment(event.canvasTimeEnd).format('YYYY-MM-DD:HH:mm:ssZZ')))
  };

  useEffect(() => {
    if (location_id) {
      dispatch(setLoading(true))

      let start = startDate.format('YYYY-MM-DD').concat('T00:00:00Z')
      let end = endDate.format('YYYY-MM-DD').concat('T23:59:59Z')

      getReservationsWithTime(start, end, location_id)

      GetReservationObjects({ token: token, admin_id: admin_id, location_id: location_id })
        .then(response => {
          dispatch(setReservationObjects(response.data))
          const newGroups = response.data.map(object => ({
            id: object.id,
            title: `${object.name} (${object.capacity})`,
            rightTitle: object.capacity,
            objectData: object
          }));

          newGroups.sort((groupA, groupB) => {
            const titleA = groupA.title.toLowerCase();
            const titleB = groupB.title.toLowerCase();

            if (titleA.startsWith("ta") && !titleB.startsWith("ta")) {
              return -1;
            } else if (!titleA.startsWith("ta") && titleB.startsWith("ta")) {
              return 1;
            } else {
              return titleA.localeCompare(titleB); // alphabetical sorting for other cases
            }
          });

          setGroups(newGroups)
        })

      dispatch(setLoading(false))
    }
    // eslint-disable-next-line
  }, [location_id]);

  useEffect(() => {
    let newReservationsWithObjects = []

    if (reservations) {
      Object.values(reservations).forEach(reservation => {
        if (reservation.status !== 'CANCELLED') {
          if (reservation.hasOwnProperty('reservation_objects')) {
            newReservationsWithObjects.push(reservation)
          }
        }
      });

      const newItems = newReservationsWithObjects.flatMap(reservation =>
        reservation.reservation_objects.map((reservation_object, index) => ({
          id: `${reservation.id}_${index}`,
          group: reservation_object.reservation_object_id,
          title: `(${reservation.number_of_people}) ${reservation.reservation_name}`,
          // start_time: moment(moment(reservation.reservation_timestamp).utc().format('YYYY-MM-DDTHH:mm:ss')).utc(),
          // start_time: moment(moment(reservation.reservation_timestamp).local().format('YYYY-MM-DDTHH:mm:ss')).utc(),
          // start_time: moment(moment(reservation.reservation_timestamp).tz('Europe/Amsterdam').format('YYYY-MM-DDTHH:mm:ss')).utc(),
          start_time: moment(moment(reservation.reservation_timestamp).tz(reservation_location?.timezone || 'Europe/Amsterdam').format('YYYY-MM-DDTHH:mm:ss')).utc(),
          // end_time: moment(moment(reservation.reservation_timestamp).utc().format('YYYY-MM-DDTHH:mm:ss')).utc().add(reservation.duration, 'minute'),
          // end_time: moment(moment(reservation.reservation_timestamp).local().format('YYYY-MM-DDTHH:mm:ss')).utc().add(reservation.duration, 'minute'),
          // end_time: moment(moment(reservation.reservation_timestamp).tz('Europe/Amsterdam').format('YYYY-MM-DDTHH:mm:ss')).utc().add(reservation.duration, 'minute'),
          end_time: moment(moment(reservation.reservation_timestamp).tz(reservation_location?.timezone || 'Europe/Amsterdam').format('YYYY-MM-DDTHH:mm:ss')).utc().add(reservation.duration, 'minute'),
          objectData: reservation,
          bgColor: bgColor,
          selectedBgColor: '#F1BA5B'
        }))
      );

      setItems(newItems);
    }
    // eslint-disable-next-line
  }, [reservations]);

  const handleBoundsChange = (canvasTimeStart, canvasTimeEnd) => {

    canvasTimeStart = Math.round(canvasTimeStart);
    canvasTimeEnd = Math.round(canvasTimeEnd);

    let start = new Date(canvasTimeStart);
    let end = new Date(canvasTimeEnd);

    start = moment(start).utc();
    end = moment(end).utc();

    start = start.startOf('day').format('YYYY-MM-DD');
    end = end.startOf('day').add(1, 'days').format('YYYY-MM-DD');

    getReservationsWithTime(start, end, location_id)

  };

  return (
    <>
      <Timeline
        groups={groups}
        items={items}
        itemsSorted
        itemTouchSendsClick={false}
        stackItems
        // fullUpdate
        itemHeightRatio={0.75}
        lineHeight={50}
        showCursorLine
        canMove={true}
        canResize="both"
        defaultTimeStart={startDate}
        defaultTimeEnd={endDate}
        onItemMove={handleItemMove}
        onItemResize={handleItemResize}
        onItemDoubleClick={handleItemDoubleClick}
        onZoom={handleZoom}
        itemRenderer={reservationTimelineItemRenderer}
        onBoundsChange={handleBoundsChange}
        minZoom={1000 * 60 * 60} // 15 minutes in milliseconds
        maxZoom={1000 * 60 * 3600}
        timeSteps={timeSteps}
        buffer={1}
        clickTolerance={200}
        resizeDetector={containerResizeDetector}
        dragSnap={5 * 60 * 1000}
      >
        <TimelineMarkers>
          <TodayMarker>
            {({ styles }) => <div style={{ ...styles, width: '0.1rem', backgroundColor: '#FF0000' }} />
            }
          </TodayMarker>
        </TimelineMarkers>
        <TimelineHeaders>
          <SidebarHeader>
          </SidebarHeader>
          <DateHeader unit="primaryHeader" />
          <DateHeader />
        </TimelineHeaders>
      </Timeline>

      <ReservationTimelineModal {...timelineModalView} />
    </>
  );
}
