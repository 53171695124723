import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setAttributeGroups, getAttributeGroupPagination, getAttributeGroups, getAttributeGroupPagesize, setAttributeGroupsAll } from "../../redux/dataSlices/attributeSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import GetAttributeList from "../../actablueAPI/products/GetAttributeList";
import BasicCard from "../../components/card"
import AttributeGroupTable from "../../components/attributeGroupTable";
import GetAllAttributegroups from "../../actablueAPI/products/GetAllAttributegroups";
import useHandleError from "../../customhooks/useHandleError";

const Attributes = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const attributeGroups = useSelector(getAttributeGroups)
  const pagination = useSelector(getAttributeGroupPagination);
  const pageSize = useSelector(getAttributeGroupPagesize);
  const dispatch = useDispatch();
  const HandleError = useHandleError();

  const attributeGroupsCard = {
    size: 12,
    title: 'Attribute groups',
    data: <AttributeGroupTable {...attributeGroups} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle('Attribute groups'))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (admin_id) {
      GetAllAttributegroups({ token: token, admin_id: admin_id, location_id: location_id })
        .then((response) => { dispatch(setAttributeGroupsAll(response)) })
        .catch(error => { HandleError({ error: error }) })
    }
    // eslint-disable-next-line
  }, [admin_id, location_id]);

  useEffect(() => {
    if (admin_id) {
      dispatch(setLoading(true))
      GetAttributeList({ token: token, admin_id: admin_id, location_id: location_id, page: pagination, size: pageSize })
        .then(response => {
          dispatch(setAttributeGroups(response.data))
          dispatch(setLoading(false))
        })
        .catch(error => {
          HandleError({ error: error })
          dispatch(setLoading(false))
        })
    }
    // eslint-disable-next-line
  }, [, admin_id, location_id, pagination, pageSize]);

  return (
    <>
      <BasicCard {...attributeGroupsCard} />
    </>
  )
};
export default Attributes;
