import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getProductCategoryPagination, getProductCategoryPagesize, setProductCategoriesAll, getProductCategoriesAll, getSelectedProductCategories, setSelectedProductCategories } from "../../redux/dataSlices/categorySlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import CategoryTable from "../../components/productcategoryTable";
import GetAllCategoriesWithType from "../../actablueAPI/products/getAllCategoriesWithType";
import { filterChoices } from "../../selectfieldchoices/categoryselect.mjs";
import SearchNoButtonAndFilterSingleSelect from "../../components/searchNoButtonAndFilterSingleSelectField";
import { CategoryMultiEditFormFields } from "./productcategoryFormFields";
import { visibleChoices } from "../../selectfieldchoices/productselect.mjs";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import ChangeHashObjectsKeyValueMulti from "../../helpers/changeHashObjectsKeyValueMulti";
import PutCategory from "../../actablueAPI/products/PutCategory";
import { confirmAlert } from "react-confirm-alert";
import AddParentToChildren from "../../helpers/addParentToChildren";
import useHandleError from "../../customhooks/useHandleError";

const Productcategories = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const productcategories = useSelector(getProductCategoriesAll);
  const selectedCategories = useSelector(getSelectedProductCategories)
  const pagination = useSelector(getProductCategoryPagination);
  const pageSize = useSelector(getProductCategoryPagesize);
  const dispatch = useDispatch();
  const [filteredCategories, setFilteredCategories] = useState()
  const [modalShow, setModalShow] = useState(false)
  const [search, setSearch] = useState()
  const [filter, setFilter] = useState()
  const [multiEditData, setMultiEditData] = useState({})
  const [refresh, setRefresh] = useState(false)
  const [reload, setReload] = useState(false)
  const HandleError = useHandleError();

  function toggleRefresh() {
    setRefresh(!refresh)
  }

  const onSearchChange = (event) => {
    let value = undefined
    if (event) {
      value = event.target.value
    }
    setSearch(value)
    SearchAndFilter(filter, value)
  }

  const onFilterChange = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setFilter(value)
    SearchAndFilter(value, search)
  }

  const onVisibleChange = (event) => {
    let newData = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: multiEditData, selectfield: 'is_visible' })
    setMultiEditData(newData)
  };

  const onSaveClick = () => {

    confirmAlert({
      title: 'Confirm to save change categories',
      message: `Are you sure you wish to change multiple categories?\n\nPlease make sure all intended categories are selected.`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let newCategories = ChangeHashObjectsKeyValueMulti(selectedCategories, multiEditData)

            const promises = [];

            for (const [, object] of Object.entries(newCategories)) {
              promises.push(PutCategory({ token: token, admin_id: admin_id, category: object }))
            }

            Promise.all(promises)
              .then(() => {
                setReload(!reload);
                setModalShow(false);
                dispatch(setSelectedProductCategories({}));
              })
              .catch((error) => {
                console.error("Error occurred during requests:", error);
              });

          }
        },
        {
          label: 'No',
        }
      ]
    });

  };

  const searchAndFilter = {
    search: {
      value: search,
      onChange: onSearchChange,
    },
    filter: {
      label: '',
      options: filterChoices,
      hash: '',
      onChange: onFilterChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    }
  }

  const toggleModal = (event) => {
    event.stopPropagation();
    setModalShow(!modalShow)
  };

  const multiEditformData = {
    modal: {
      title: "Category multi edit",
      show: modalShow,
      onSaveClick: onSaveClick,
      toggleModal: toggleModal,
      fields: CategoryMultiEditFormFields(),
      is_visible: {
        label: 'is_visible',
        placeholder: 'Select...',
        options: visibleChoices,
        selected: [],
        onChange: onVisibleChange,
        clearable: Boolean(true),
        searchable: Boolean(false)
      },
      data: multiEditData,
      selected: selectedCategories
    }
  }

  const categoriesCard = {
    size: 12,
    title: `Product categories (Total: ${productcategories?.length || 0})`,
    data: <CategoryTable categories={filteredCategories || productcategories} search={search} filter={filter} multiEdit={multiEditformData} refresh={toggleRefresh} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle('Product categories'))
    // eslint-disable-next-line
  }, []);

  function SearchAndFilter(filter, search) {
    if (!filter && !search) {
      return setFilteredCategories()
    }

    let filtered = []
    switch (filter) {
      case 'visible_true':
        filtered = productcategories.filter(category => category.is_visible)
        break;
      case 'visible_false':
        filtered = productcategories.filter(category => !category.is_visible)
        break;
      default:
        filtered = productcategories
        break;
    }

    if (search) {
      return setFilteredCategories(filtered.filter(categorie => JSON.stringify(categorie.title).toLowerCase().includes(search.toLowerCase())))
    }
    return setFilteredCategories(filtered)
  }

  useEffect(() => {
    dispatch(setLoading(true))
    GetAllCategoriesWithType({ type: 'PRODUCTPAGE', token: token, admin_id: admin_id, location_id: location_id })
    .then(response => {
      // create array with unique ojects
      const unique = [...new Map(response.map(object => [object['id'], object])).values()]
      // add parent ids to children
      const modifiedCategoryList = AddParentToChildren(unique)
      dispatch(setProductCategoriesAll(modifiedCategoryList))
      dispatch(setLoading(false))
    })
    .catch(error => {
      HandleError({ error: error })
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, [, admin_id, location_id, pagination, pageSize, reload, refresh]);

  return (
    <>
      <SearchNoButtonAndFilterSingleSelect {...searchAndFilter} />
      <BasicCard {...categoriesCard} />
    </>
  );
};
export default Productcategories;
