import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setWarehousesAll, getWarehousesAll, getRack, } from "../../redux/dataSlices/warehouseSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import WarehouseTable from "../../components/warehouseTable";
import GetWarehouseWarehouse from "../../actablueAPI/warehouse/GetWarehouseWarehouse";
import useHandleError from "../../customhooks/useHandleError";

const Warehouse = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const warehouses = useSelector(getWarehousesAll)
  const rack = useSelector(getRack)
  const dispatch = useDispatch();
  const HandleError = useHandleError();
  const navTitle = "Warehouse"

  const card = {
    size: 12,
    title: navTitle,
    data: <WarehouseTable {...warehouses} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (admin_id) {
      dispatch(setLoading(true))
      GetWarehouseWarehouse({token:token, admin_id:admin_id, location_id:location_id})
      .then((warehousesResponse) => {
        dispatch(setWarehousesAll(warehousesResponse.data.content))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
    }
    // eslint-disable-next-line
  }, [, admin_id, location_id, rack]);

  return (
    <>
      <BasicCard {...card} />
    </>
  );
};
export default Warehouse;
