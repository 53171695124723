import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getAdministrationId } from "../../redux/dataSlices/administrationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import AdminForm from "../../components/adminForm";
import AdminFormFields from "./adminFormFields.jsx";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { countryCodeChoices } from "../../selectfieldchoices/country_code.mjs";
import PostAdministration from "../../actablueAPI/serials/PostAdministration.jsx";
import GetSerialById from "../../actablueAPI/serials/GetSerialByID.jsx";

const AdminEdit = () => {
  const token = useSelector(getToken);
  const administration_id = useSelector(getAdministrationId)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navTitle = "Administration edit"
  const [administration, setAdministration] = useState({})

  const onChange = (event) => {
    let newAdministration = HandleOnChange({ event: event, object: administration })
    setAdministration(newAdministration)
  }

  const onCountryCodeChange = (event) => {
    let newAdministration = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: administration, selectfield: 'country_code' })
    setAdministration(newAdministration)
  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    PostAdministration({token:token, data:administration})
    .then(response => {
      navigate("/administrations");
    })

  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: AdminFormFields(),
    field: {
      onChange: onChange
    },
    data: administration,
    country_code: {
      label: 'country_code',
      options: countryCodeChoices,
      selected: [],
      onChange: onCountryCodeChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  };

  const editCard = {
    size: 12,
    title: {text: navTitle, option: administration.name},
    data: <AdminForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    GetSerialById({token:token, id:administration_id})
    .then(response => {
      setAdministration(response.data)
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default AdminEdit;

