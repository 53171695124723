import { Table } from 'react-bootstrap'
import Button from "react-bootstrap/Button";

import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import GetRelationById from '../actablueAPI/relations/GetRelationByID';
import { useSelector } from 'react-redux';
import { getToken } from '../redux/dataSlices/tokenSlice';
import { useEffect, useState } from 'react';
import useHandleError from '../customhooks/useHandleError';

export default function SuppliersEditTable(props) {
  const token = useSelector(getToken);
  const suppliers = props?.suppliers
  const edit = suppliers.hasOwnProperty('edit') ? Object.assign({}, suppliers.edit) : false
  const HandleError = useHandleError();

  const [relationNames, setRelationNames] = useState({});
  const { t: translate } = useTranslation();

  let rows = []
  let selected = props.suppliers?.selected ?? []

  useEffect(() => {
    const names = JSON.parse(JSON.stringify(relationNames))

    Object.entries(suppliers?.data).forEach(async ([key, object]) => {
      try {
        const response = await GetRelationById({ token: token, id: object?.relation_id, admin_id: object.admin_id });
        names[key] = response.data.name;
      } catch (error) {
        HandleError({ error: error })
      }
    })

    setRelationNames(names);
    // eslint-disable-next-line
  }, [Object.entries(suppliers?.data).length]);

  if (suppliers) {
    Object.entries(suppliers?.data).forEach(([key, object]) => {
      rows.push(
        <tr key={key} className='product-row'>
          <td><Form.Check onChange={props?.suppliers?.check?.onChange} id={object.id} /></td>
          <td>{relationNames[key]}</td>
          {/* <td>{relationNames[key] ? relationNames[key] : 'Loading name...'}</td> */}
          <td align="right">{Number(object.supplier_price).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
          <td>
            {object?.supplier_artikel_code}
          </td>
          <td>
            {edit && <Button size="sm" type='button' onClick={(event) => { edit.onClick(event, key) }} id={key}>{translate(edit.tag)}</Button>}
          </td>
        </tr>
      )
    });
  }

  return (
    <>
      <Table hover width='100%'>
        <thead>
          <tr>
            <th width='3%'></th>
            <th width='27%'>{translate('Supplier')}</th>
            <th className="text-end">{translate('Price')}</th>
            <th>{translate('Supplier product code')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <Button variant="primary" className='mt-3' type={props.suppliers?.add?.type} onClick={props.suppliers?.add?.onClick} name={props.suppliers?.add?.name}>
        {props.suppliers?.add?.title}
      </Button>
      <Button variant="primary" className='mt-3' type={props.suppliers?.remove?.type} onClick={props.suppliers?.remove?.onClick} name={props.suppliers?.remove?.name} disabled={selected.length === 0 ? true : false}>
        {props.suppliers?.remove?.title}
      </Button>
    </>
  );
}