import { Button, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

import './css/productTable.css';

import PaginationAndSizeComponent from './paginationAndSize';
import PrinterButtons from '../portal/kitchenmanager/printerButtons';
import KmPrinterModal from './kmPrinterModal';

export default function KmPrinterTable(props) {
  const printers = props?.printers?.items
  const pages = props?.printers?.pages
  const page = props?.printers?.page

  const buttons = PrinterButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const del = buttons.hasOwnProperty('delete') ? Object.assign({}, buttons.delete) : false
  const { t: translate } = useTranslation();
  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let rows = []

  if (printers) {
    for (const printer of Object.values(printers)) {
      rows.push(
        <tr className='product-row' key={printer.id}>
          <td>{printer?.name ?? 'Unknown'}</td>
          <td>{printer?.type}</td>
          <td>{printer?.serial_number ?? 'None'}</td>
          <td className='text-center'><div className='table-icons'>{printer?.active ? <i className="bi bi-check-circle true"></i> : <i className="bi bi-x-square false"></i>}</div></td>
          <td>
            <p>
              {translate('Created')}: {new Date(printer?.creation).toLocaleString()}
            </p>
            <p>
              {translate('Modified')}: {new Date(printer?.modification).toLocaleString()}
            </p>
          </td>
          <td>
            {modalview && <Button size="sm" type='button' onClick={() => { modalview.onClick(printer) }} id={printer?.id}>{translate(modalview.tag)}</Button>}
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(printer) }} id={printer?.id}>{translate(edit.tag)}</Button>}
            {del && <Button size="sm" type='button' onClick={() => { del.onClick(printer) }} id={printer.id}>{translate(del.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }

  return (
    <>
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      {add && <Button className={add.className} size="sm" type='button' onClick={() => { add.onClick() }}>{translate(add.tag)}</Button>}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Type')}</th>
            <th>{translate('Serial Number')}</th>
            <th className='text-center'>{translate('Active')}</th>
            <th>
              {translate('Date')}
            </th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <KmPrinterModal modal={modalview.modal} />
    </>
  );
}