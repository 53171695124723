import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getProducts, setProducts, getProductPagesize, getProductPagination, getSelectedProducts, setSelectedProducts } from "../../redux/dataSlices/productSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { getTemplatesAll, setTemplatesAll } from "../../redux/dataSlices/templateSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import BasicCard from "../../components/card"
import ProductEanTable from "../../components/productEanTable";
import SearchAndFilter from "../../components/searchAndFilter";
import { productFilterChoices } from "../../selectfieldchoices/productselect.mjs";
import { getSettingsSchapkaartjes, setSettingsSchapkaartjes } from "../../redux/dataSlices/settingsSlice";
import { getProgressModalShow, setProgressModalShow, setTaskData } from "../../redux/dataSlices/analyseSlice";
import ProgressModal from "../../components/progressModal";
import GetAllTemplates from "../../actablueAPI/shelflabels/GetAllTemplates";
import GetAllPrinters from "../../actablueAPI/shelflabels/GetAllPrinters";
import { setPrintersAll } from "../../redux/dataSlices/printerSlice";
import { confirmAlert } from "react-confirm-alert";
import ChangeHashObjectsKeyValueMulti from "../../helpers/changeHashObjectsKeyValueMulti";
import { ProductMultiEditFormFields } from "./productFormFields";
import PutProduct from "../../actablueAPI/products/PutProduct";
import GetAllCategoriesWithType from "../../actablueAPI/products/getAllCategoriesWithType";
import { getHashJournalCategoriesAll, getJournalCategoriesAll, setJournalCategoriesAll } from "../../redux/dataSlices/categorySlice";
import HandleOnChangeJournalCategory from "../../helpers/handleOnChangeJournalCategory";
import { getHashVatsAll, getVatsAll, setVatsAll } from "../../redux/dataSlices/vatSlice";
import HandleOnChangeSingleSelectFieldWithObject from "../../helpers/handleOnChangeSingleSelectFieldWithObject";
import GetAllVats from "../../helpers/getAllVats";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { visibleChoices } from "../../selectfieldchoices/productselect.mjs";
import PostProductSearch from "../../actablueAPI/products/PostProductsSearch";
import GetProductScanLive from "../../actablueAPI/products/GetProductsScanLive";
import DelProductIdFromCategory from "../../actablueAPI/products/DelProductIdFromCategory";
import GetProductsType from "../../actablueAPI/products/GetProductsType";
import GetShelflabelsSettings from "../../actablueAPI/shelflabels/GetShelflabelsSettings";
import useHandleError from "../../customhooks/useHandleError";

const ProductEans = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const journalCategories = useSelector(getJournalCategoriesAll)
  const hash_journal_categories = useSelector(getHashJournalCategoriesAll)
  const vats_all = useSelector(getVatsAll)
  const hash_vats_all = useSelector(getHashVatsAll)
  const products = useSelector(getProducts);
  const selectedProducts = useSelector(getSelectedProducts)
  const templates = useSelector(getTemplatesAll);
  const pagination = useSelector(getProductPagination)
  const pageSize = useSelector(getProductPagesize)
  const settings = useSelector(getSettingsSchapkaartjes)
  const scopes = useSelector(getScopes)
  const modal_show = useSelector(getProgressModalShow)
  const dispatch = useDispatch();
  const [filter, setFilter] = useState('article')
  const [search, setSearch] = useState('')
  const [modalShow, setModalShow] = useState(false)
  const [multiEditData, setMultiEditData] = useState({})
  const [reload, setReload] = useState(false)
  const HandleError = useHandleError();
  const navTitle = "Product Eans"

  const onSearchChange = (event) => {
    setSearch(event.target.value)
  }

  const onSearchKeyDown = (key) => {
    if (key.keyCode === 13) {
      onSearchClick()
    }
  }

  const onSearchClick = () => {
    if (search !== '') {
      dispatch(setLoading(true))
      const searchDataObject = {
        'text': search,
        'fuzziness': 2,
        'type': filter,
        'fields': ['name', 'skuCode'],
        'limit': 100
      }
      axios.all([
        PostProductSearch({ token: token, admin_id: admin_id, location_id: location_id, data: searchDataObject }),
        GetProductScanLive({ token: token, admin_id: admin_id, location_id: location_id, ean: search }),
      ])
        .then(axios.spread((searchResponse, scanResponse) => {
          let newProducts = { 'content': [] }
          newProducts.content = [...searchResponse.data, ...scanResponse.data]
          dispatch(setProducts(newProducts))
          dispatch(setLoading(false))
        }))
        .catch(error => {
          HandleError({ error: error })
          dispatch(setLoading(false))
        })
    }
    if (search === '') {
      GetProductsWithFilter()
    }
    setSearch('')
  }

  const onFilterChange = (event) => {
    if (event) {
      setFilter(event.value)
    } else {
      setFilter('article')
    }
  }

  const searchAndFilter = {
    search: {
      value: search,
      onChange: onSearchChange,
      onClick: onSearchClick,
      onKeyDown: onSearchKeyDown,
    },
    filter: {
      selected: { 'id': filter, 'name': filter },
      options: productFilterChoices,
      onChange: onFilterChange,
      clearable: Boolean(false)
    }
  }

  const onSaveClick = () => {

    confirmAlert({
      title: 'Confirm to change multiple products',
      message: `Are you sure you wish to change multiple products?\n\nPlease make sure all intended products are selected.`,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              let newProducts = ChangeHashObjectsKeyValueMulti(selectedProducts, multiEditData)

              let current = 1
              let end = Object.keys(selectedProducts).length
              let task_data = {
                current: current,
                total: end,
                progress: current === 0 ? 0 : current / end
              }

              dispatch(setTaskData(task_data))
              dispatch(setProgressModalShow(true))

              async function multiEditProducts() {
                if (current <= end) {
                  const current_id = Object.keys(selectedProducts)[(current - 1)]
                  const newProduct = newProducts[current_id];
                  const oldProduct = selectedProducts[current_id];

                  if (multiEditData.categories && multiEditData.categories.length >= 1) {
                    if (newProduct && oldProduct) {
                      for (const category of Object.values(oldProduct.categories)) {
                        if (category.type === 'JOURNAL') {
                          await DelProductIdFromCategory({ token: token, admin_id: admin_id, location_id: location_id, category_id: category.id, product_id: current_id })
                        }
                        if (category.type === 'PRODUCTPAGE') {
                          newProduct.categories.push(category);
                        }
                      }
                    }
                  }

                  await PutProduct({ token: token, admin_id: admin_id, data: newProduct });

                  task_data = {
                    current: current,
                    total: end,
                    progress: current === 0 ? 0 : current / end
                  }

                  current++
                  dispatch(setTaskData(task_data))
                  setTimeout(multiEditProducts, 1);
                } else {
                  setReload(!reload);
                  setModalShow(false);
                  dispatch(setProgressModalShow(false));
                  dispatch(setSelectedProducts({}));
                  setMultiEditData({});
                }
              }

              multiEditProducts();
            } catch (error) {
              if (!error.code === "ERR_BAD_REQUEST") {
                HandleError({ error: "An unknown error occurred." })
                setReload(!reload);
                setModalShow(false);
                dispatch(setProgressModalShow(false));
                dispatch(setSelectedProducts({}));
                setMultiEditData({});
                return false;
              }
            }
          }
        },
        {
          label: 'No',
        }
      ]
    });
  };

  const toggleModal = (event) => {
    event.stopPropagation();
    setModalShow(!modalShow)
  };

  const onActiveChange = (event) => {
    let newData = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: multiEditData, selectfield: 'active' })
    setMultiEditData(newData)
  };

  const onAskSnChange = (event) => {
    let newData = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: multiEditData, selectfield: 'ask_sn' })
    setMultiEditData(newData)
  };

  const onIsWeightedChange = (event) => {
    let newData = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: multiEditData, selectfield: 'is_weighted' })
    setMultiEditData(newData)
  };

  const onJournalCategoriesChange = (event) => {
    let [newData,] = HandleOnChangeJournalCategory({ event: event, object: multiEditData, hash: hash_journal_categories, deleteList: [] })
    setMultiEditData(newData)
  };

  const onVatChange = (event) => {
    let newData = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: multiEditData, selectfield: 'vat', hash: hash_vats_all })
    setMultiEditData(newData)
  };

  const multiEditformData = {
    modal: {
      title: "Product multi edit",
      show: modalShow,
      onSaveClick: onSaveClick,
      toggleModal: toggleModal,
      fields: ProductMultiEditFormFields(),
      data: multiEditData,
      selected: selectedProducts,
      journalcategories: {
        name: 'journalcategories',
        options: journalCategories,
        selected: null,
        onChange: onJournalCategoriesChange,
        clearable: Boolean(true),
        searchable: Boolean(true)
      },
      vats: {
        label: 'vat',
        options: vats_all,
        onChange: onVatChange,
        clearable: Boolean(true),
        searchable: Boolean(true)
      },
      active: {
        label: 'active',
        placeholder: 'Select...',
        options: visibleChoices,
        selected: [],
        onChange: onActiveChange,
        clearable: Boolean(true),
        searchable: Boolean(false)
      },
      ask_sn: {
        label: 'ask_sn',
        placeholder: 'Select...',
        options: visibleChoices,
        selected: [],
        onChange: onAskSnChange,
        clearable: Boolean(true),
        searchable: Boolean(false)
      },
      is_weighted: {
        label: 'is_weighted',
        placeholder: 'Select...',
        options: visibleChoices,
        selected: [],
        onChange: onIsWeightedChange,
        clearable: Boolean(true),
        searchable: Boolean(false)
      }
    }
  }

  const productsCard = {
    size: 12,
    title: navTitle,
    data: <ProductEanTable products={products} templates={templates} settings={settings} multiEdit={multiEditformData} />,
  };

  const onViewHide = () => {
    dispatch(setProgressModalShow(false));
  };

  const progressModal = {
    modal: {
      show: modal_show,
      title: 'Processing...',
      onHide: onViewHide
    }
  }

  const GetProductsWithFilter = () => {
    dispatch(setLoading(true))
    GetProductsType({ token: token, admin_id: admin_id, location_id: location_id, page: pagination, size: pageSize, filter: filter })
      .then(response => {
        dispatch(setProducts(response.data))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
  }

  const GetSettings = () => {
    GetShelflabelsSettings({ token: token, admin_id: admin_id, location_id: location_id })
      .then(response => {
        dispatch(setSettingsSchapkaartjes(response.data))
      })
      .catch(error => {
        HandleError({ error: error })
      })
  }

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (admin_id) {
      getDataFromApi()
      dispatch(setLoading(true))
      onSearchClick()
    }
    // eslint-disable-next-line
  }, [admin_id, location_id, filter, pagination, pageSize, reload]);

  async function getDataFromApi() {
    if (!journalCategories || journalCategories?.length <= 0) {
      await GetAllCategoriesWithType({ type: 'JOURNAL', token: token, admin_id: admin_id, location_id: location_id })
        .then(async (response) => { dispatch(setJournalCategoriesAll(response)) })
    }

    if (!vats_all || vats_all?.length <= 0) {
      await GetAllVats({ token: token, admin_id: admin_id, location_id: location_id })
        .then(async (response) => { dispatch(setVatsAll(response)) })
    }

    if (location_id && scopes.schapkaartjes) {
      await GetAllTemplates({ token: token, admin_id: admin_id, location_id: location_id, base_labels: true, sorted: true, page: 1, size: 25 })
        .then(response => { dispatch(setTemplatesAll(response)) })
        .catch(error => { HandleError({ error: error }) })

      await GetAllPrinters({ token: token, admin_id: admin_id, location_id: location_id })
        .then(response => { dispatch(setPrintersAll(response)) })
        .catch(error => { HandleError({ error: error }) })

      GetSettings({ token: token, admin_id: admin_id, location_id: location_id })
    }
  }

  return (
    <>
      <ProgressModal {...progressModal} />
      {location_id && <SearchAndFilter {...searchAndFilter} />}
      <BasicCard {...productsCard} />
    </>
  );
};
export default ProductEans;