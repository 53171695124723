import { createSlice } from "@reduxjs/toolkit";

export const kitchenmanagerSlice = createSlice({
  name: "kitchenmanager",
  initialState: {
    sortcategory_pagesize: 10,
    sortcategory_pagination: 1,
    hash_sortcategories_all: {},
    printer_pagesize: 10,
    printer_pagination: 1,
    hash_printers_all: {},
    screen_pagesize: 10,
    screen_pagination: 1,
    hash_screens_all: {}
  },
  reducers: {
    setSortCategoryId: (state, action) => {
      state.sortcategory_id = action.payload;
    },
    setSortCategory: (state, action) => {
      state.sortcategory = action.payload;
    },
    setSortCategories: (state, action) => {
      state.sortcategories = action.payload
    },
    setSortCategoriesAll: (state, action) => {
      state.sortcategories_all = action.payload

      if (action?.payload?.items) {
        action?.payload?.items?.forEach(sort_category => {
          state.hash_sortcategories_all[sort_category.id] = sort_category
        });
      } else {
        action?.payload?.forEach(sort_category => {
          state.hash_sortcategories_all[sort_category.id] = sort_category
        });
      }
    },
    setSortCategoryModalShow: (state, action) => {
      state.sortcategory_modalshow = action.payload
    },
    setSortCategoryPagination: (state, action) => {
      state.sortcategory_pagination = action.payload
    },
    setSortCategoryPagesize: (state, action) => {
      state.sortcategory_pagesize = action.payload
    },
    setKitchenManagerSettings: (state, action) => {
      state.km_settings = action.payload
    },
    setPrinterId: (state, action) => {
      state.printer_id = action.payload;
    },
    setPrinter: (state, action) => {
      state.printer = action.payload;
    },
    setPrinters: (state, action) => {
      state.printers = action.payload
    },
    setPrintersAll: (state, action) => {
      state.printers_all = action.payload

      if (action?.payload?.items) {
        action?.payload?.items?.forEach(printer => {
          state.hash_printers_all[printer.id] = printer
        });
      } else {
        action?.payload?.forEach(printer => {
          state.hash_printers_all[printer.id] = printer
        });
      }
    },
    setPrinterModalShow: (state, action) => {
      state.printer_modalshow = action.payload
    },
    setPrinterPagination: (state, action) => {
      state.printer_pagination = action.payload
    },
    setPrinterPagesize: (state, action) => {
      state.printer_pagesize = action.payload
    },
    setScreenId: (state, action) => {
      state.screen_id = action.payload;
    },
    setScreen: (state, action) => {
      state.screen = action.payload;
    },
    setScreens: (state, action) => {
      state.screens = action.payload
    },
    setScreensAll: (state, action) => {
      state.screens_all = action.payload

      if (action?.payload?.items) {
        action?.payload?.items?.forEach(screen => {
          state.hash_screens_all[screen.id] = screen
        });
      } else {
        action?.payload?.forEach(screen => {
          state.hash_screens_all[screen.id] = screen
        });
      }
    },
    setScreenModalShow: (state, action) => {
      state.screen_modalshow = action.payload
    },
    setScreenPagination: (state, action) => {
      state.screen_pagination = action.payload
    },
    setScreenPagesize: (state, action) => {
      state.screen_pagesize = action.payload
    },
  }
});

export const {
  setSortCategoryId, setSortCategory, setSortCategories, setSortCategoriesAll, setSortCategoryModalShow, setSortCategoryPagination, setSortCategoryPagesize,
  setKitchenManagerSettings,
  setPrinterId, setPrinter, setPrinters, setPrintersAll, setPrinterModalShow, setPrinterPagination, setPrinterPagesize,
  setScreen, setScreenId, setScreenModalShow, setScreenPagesize, setScreenPagination, setScreens, setScreensAll
} = kitchenmanagerSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getSortCategoryId = (state) => state.kitchenmanager.sortcategory_id
export const getSortCategory = (state) => state.kitchenmanager.sortcategory
export const getSortCategories = (state) => state.kitchenmanager.sortcategories
export const getSortCategoriesAll = (state) => state.kitchenmanager.sortcategories_all
export const getHashSortCategoriesAll = (state) => state.kitchenmanager.hash_sortcategories_all
export const getSortCategoryModalShow = (state) => state.kitchenmanager.sortcategory_modalshow
export const getSortCategoryPagination = (state) => state.kitchenmanager.sortcategory_pagination
export const getSortCategoryPageSize = (state) => state.kitchenmanager.sortcategory_pagesize

export const getKitchenManagerSettings = (state) => state.kitchenmanager.km_settings

export const getPrinterId = (state) => state.kitchenmanager.printer_id
export const getPrinter = (state) => state.kitchenmanager.printer
export const getPrinters = (state) => state.kitchenmanager.printers
export const getPrintersAll = (state) => state.kitchenmanager.printers_all
export const getHashPrintersAll = (state) => state.kitchenmanager.hash_printers_all
export const getPrinterModalShow = (state) => state.kitchenmanager.printer_modalshow
export const getPrinterPagination = (state) => state.kitchenmanager.printer_pagination
export const getPrinterPageSize = (state) => state.kitchenmanager.printer_pagesize

export const getScreenId = (state) => state.kitchenmanager.printer_id
export const getScreen = (state) => state.kitchenmanager.screen
export const getScreens = (state) => state.kitchenmanager.screens
export const getScreensAll = (state) => state.kitchenmanager.screens_all
export const getHashScreensAll = (state) => state.kitchenmanager.hash_screens_all
export const getScreenModalShow = (state) => state.kitchenmanager.screen_modalshow
export const getScreenPagination = (state) => state.kitchenmanager.screen_pagination
export const getScreenPageSize = (state) => state.kitchenmanager.screen_pagesize

export default kitchenmanagerSlice.reducer;