import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getWarehousesAll, getHashWarehousesAll, getWarehouse, setWarehouse, setWarehousesAll } from "../../redux/dataSlices/warehouseSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import RackForm from "../../components/rackForm";
import RackFormFields from "./rackFormFields";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import PostWarehouseRack from "../../actablueAPI/warehouse/PostWarehouseRack";
import GetWarehouseWarehouse from "../../actablueAPI/warehouse/GetWarehouseWarehouse";

const RackAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const warehouse = useSelector(getWarehouse)
  const warehouses = useSelector(getWarehousesAll)
  const hash_warehouses = useSelector(getHashWarehousesAll)
  // const rack = useSelector(getRack)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rack, setRack] = useState({ warehouse_id: warehouse.id })

  const onChange = (event) => {
    let newRack = HandleOnChange({ event: event, object: rack })
    setRack(newRack)
  }

  const onWarehouseIdChange = (event) => {
    let newRack = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: rack, selectfield: 'warehouse_id' })
    setRack(newRack)
  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    let newRack = Object.assign({}, rack)
    if (!newRack.description) {
      delete newRack.description
    }

    let newWarehouse = Object.assign({}, warehouse)
    newWarehouse.racks = newWarehouse.racks.filter(excisting_rack => excisting_rack.id !== newRack.id)
    if (newWarehouse.id === newRack.warehouse_id) {
      newWarehouse.racks.push(newRack)
    }
    dispatch(setWarehouse(newWarehouse))

    PostWarehouseRack({token:token, data:newRack})
    .then(putResponse => {
      GetWarehouseWarehouse({token:token, admin_id:admin_id, location_id:location_id})
      .then(warehousesResponse => {
        dispatch(setWarehousesAll(warehousesResponse.data.content))
        navigate("/warehouse");
      })
    })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: RackFormFields(),
    field: {
      onChange: onChange
    },
    data: rack,
    warehouse_id: {
      label: 'warehouse_id',
      options: warehouses,
      hash: hash_warehouses,
      selected: {},
      onChange: onWarehouseIdChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    }
  };

  const card = {
    size: 12,
    title: "Rack add ",
    data: <RackForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Rack add"))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...card} />
    </>
  )

};
export default RackAdd;

