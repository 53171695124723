import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import EmployeeForm from "../../components/employeeForm";
import { EmployeeFormFields } from "./employeeFormFields";
import HandleOnChange from "../../helpers/handleOnChange";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getEmployee, getEmployeeRolesAll, getHashEmployeeRolesAll, setEmployee } from "../../redux/dataSlices/employeeSlice";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import PostEmployee from "../../actablueAPI/employee/PostEmployee";
import { roles } from "../../scopes/roles.mjs";
import useHandleError from "../../customhooks/useHandleError";

const EmployeeEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const employee = useSelector(getEmployee)
  const employee_roles_all = useSelector(getEmployeeRolesAll)
  const hash_employee_roles_all = useSelector(getHashEmployeeRolesAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navTitle = "Employee edit"
  const HandleError = useHandleError();

  const onChange = (event) => {
    let newEmployee = HandleOnChange({ event: event, object: employee })
    dispatch(setEmployee(newEmployee))
  }

  const onRoleChange = (event) => {
    let newEmployee = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: employee, selectfield: 'role' })
    newEmployee.roles = hash_employee_roles_all[event.value].roles
    dispatch(setEmployee(addRolesToArray(newEmployee)))
  };

  const onCancel = () => { navigate(-1) }

  const onSubmit = () => {
    console.log('employeeEdit', employee)
    // set scopes back to array with stings
    let newEmployee = JSON.parse(JSON.stringify(employee))

    if (employee.roles) {
      let newRoles = []
      for (const [role, value] of Object.entries(employee.roles)) {
        // clean scopes (delete non excisting scopes)
        if (roles.includes(role)) {
          newRoles.push(role.concat(':').concat(value))
        }
      }
      newEmployee.roles = newRoles
    }
    delete newEmployee.role

    console.log('employeeEdit', newEmployee)

    PostEmployee({ token: token, admin_id: admin_id, employee: newEmployee })
      .then(response => {
        navigate("/employees");
      })
      .catch(error => {
        HandleError({ error: error })
      })

  };

  const addRolesToArray = (employee) => {
    let newEmployee = JSON.parse(JSON.stringify(employee))
    if (Array.isArray(employee?.roles)) {
      let newRoles = {}
      for (const scope of Object.values(employee.roles)) {
        const splitted = scope.split(':')
        const last = splitted[splitted.length - 1]
        const field = scope.replace(":".concat(last), '')
        newRoles[field] = last
      }
      newEmployee.roles = newRoles
    }
    return newEmployee
  }

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: EmployeeFormFields(),
    field: {
      onChange: onChange
    },
    data: employee,
    role: {
      label: 'role',
      options: employee_roles_all,
      onChange: onRoleChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  };

  const editCard = {
    size: 12,
    title: navTitle,
    data: <EmployeeForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default EmployeeEdit;

