import React from "react";
import { useState, useEffect } from "react";
import moment from "moment-timezone";
import { useSelector, useDispatch } from "react-redux";
import { setError, setResponseError } from "../../redux/dataSlices/errorSlice";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card";
import { planningFormFields } from "./planningFormFields.jsx"
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue"
import DeepCopy from "../../helpers/DeepCopy.jsx";
import { planningTypeChoices, statusFilterChoices } from "../../selectfieldchoices/planningselect.mjs";
import PlanningForm from "../../components/planningForm.jsx";
import { GetAllPlanningObjects, PostPlanning } from "../../actablueAPI/planning/Planning.jsx";
import { getHashPlanningObjectsAll, getPlanningObjectsAll, setPlanningObjectsAll } from "../../redux/dataSlices/planningSlice.js";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice.js";
import HandleOnChangeMultiSelectFieldWithObjects from "../../helpers/handleOnChangeMultiSelectFieldWithObjects.jsx";
import { validateFormObject } from "../../helpers/validateFormObject.jsx";
import useHandleError from "../../customhooks/useHandleError.jsx";

const PlanningAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const planning_objects = useSelector(getPlanningObjectsAll);
  const hash_planning_objects = useSelector(getHashPlanningObjectsAll);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [planning, setPlanning] = useState({ location_id: location_id, type: 'PLANNING', status: 'PENDING' })
  const [invalidFields, setInvalidFields] = useState([]);
  const HandleError = useHandleError();

  const onChange = (event) => {
    let newPlanning = HandleOnChange({ event: event, object: planning })
    setPlanning(newPlanning)
  };

  const onTypeChange = (event) => {
    let newPlanning = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: planning, selectfield: 'type' })
    setPlanning(newPlanning)
  };

  const onStatusChange = (event) => {
    let newPlanning = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: planning, selectfield: 'status' })
    setPlanning(newPlanning)
  };

  const onPlanningObjectChange = (event) => {
    let newPlanning = HandleOnChangeMultiSelectFieldWithObjects({ event: event, object: planning, selectfield: 'planning_objects', hash: hash_planning_objects })
    setPlanning(newPlanning)
  };

  const onCancel = () => {navigate(-1)}

  const onSubmit = (event) => {
    const [invalid] = validateFormObject({ object: planning, fields: formData?.fields, switchTab: true, dispatch: dispatch })

    if (invalid?.length > 0) {
      setInvalidFields(invalid)
      return;
    }

    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    let newPlanning = DeepCopy(planning)
    newPlanning.type = planning.type.toUpperCase()
    newPlanning.status = planning.status.toUpperCase()

    newPlanning.planning_timestamp = moment(planning.planning_timestamp).utc().format();

    if (!planning.planning_objects) planning.planning_objects = []

    if (newPlanning?.planning_objects) {
      for (const planning_object of newPlanning?.planning_objects) {
        planning_object.planning_object_id = planning_object.id
        delete planning_object.id
      }
    }

    PostPlanning({ token: token, data: newPlanning })
      .then(() => {
        navigate("/planning");
      })
      .catch(error => {
        const type = error.response?.type
        const data = error.response?.data
        if (error.response) { dispatch(setResponseError({ type, data })) }
        if (!error.response) { dispatch(setError(JSON.stringify(error))) }
      })

  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    invalidFields: invalidFields,
    fields: planningFormFields,
    field: {
      onChange: onChange
    },
    data: planning,
    type: {
      name: 'type',
      options: planningTypeChoices,
      selected: '',
      onChange: onTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    status: {
      name: 'status',
      options: statusFilterChoices,
      selected: '',
      onChange: onStatusChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    planning_objects: {
      name: 'planning_objects',
      options: planning_objects,
      selected: [],
      onChange: onPlanningObjectChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    }
  };

  const addCard = {
    size: 12,
    title: "Planning add",
    data: <PlanningForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setLoading(true))
    GetAllPlanningObjects({ token: token, admin_id: admin_id, location_id: location_id })
      .then(response => {
        dispatch(setPlanningObjectsAll(response))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )
};
export default PlanningAdd;
