import { Modal, ProgressBar } from 'react-bootstrap';
// import { useTranslation } from 'react-i18next';
import './css/modal.css'
import { useSelector } from 'react-redux';
import { getTaskData } from '../redux/dataSlices/analyseSlice';

export default function ProgressModal(props) {
  // const { t: translate } = useTranslation();
  const task_data = useSelector(getTaskData)
  let title = props?.modal?.title
  let now = task_data ? task_data.progress*100 : 0
  let label = `${task_data ? Math.round(task_data.progress*100) : 0}%`
  let current = task_data?.current
  let total = task_data?.total
  let subTitle = undefined
  let subNow = undefined
  let subLabel = undefined
  let subCurrent = undefined
  let subTotal = undefined

  if (props.task_data) {
    title =  props.task_data.title
    now = props.task_data.progress
    label = `${Math.round(props.task_data.progress)}%`
    current = props.task_data.current
    total = props.task_data.total
    subTitle =  props.task_data.subTitle
    subNow = props.task_data.subProgress
    subLabel = `${Math.round(props.task_data.subProgress)}%`
    subCurrent = props.task_data.subCurrent
    subTotal = props.task_data.subTotal
  }

  let progressBar = []
  
  if (title) {
    progressBar.push(<ProgressBar animated label={label} now={now} key={title}/>)
    progressBar.push(<p key={title+'p'}>{current} out of {total}</p>)
  }

  if (subTitle) {
    <Modal.Title>{subTitle}</Modal.Title>
    progressBar.push(<ProgressBar animated label={subLabel} now={subNow} key={subTitle}/>)
    progressBar.push(<p key={subTitle+'p'}>{subCurrent} out of {subTotal}</p>)
  }

  return (
    <Modal
    show={props.modal.show}
    size="lg"
    dialogClassName='modal-fullscreen-md-down modal-md'
    contentClassName='h-md-100'
    aria-labelledby="contained-modal-title-vcenter"
    centered scrollable
  >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {progressBar}
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={props.modal.onHide}>{translate('Close')}</Button> */}
      </Modal.Footer>
  </Modal>
  );
}
