import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getRole, getToken } from "../../redux/dataSlices/tokenSlice";
import { useSelector, useDispatch } from "react-redux";
import { getWarehouseModalShow, getWarehousePagesize, setWarehouse, setWarehouseModalShow, setWarehousePageSize, setWarehousePagination } from "../../redux/dataSlices/warehouseSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";

import { pagesize } from "../../selectfieldchoices/pagesize.mjs";
import GetImport from "../../actablueAPI/warehouse/GetImport";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { confirmAlert } from "react-confirm-alert";
import useHandleError from "../../customhooks/useHandleError";

export default function WarehouseButtons() {
  const token = useSelector(getToken)
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const modalShow = useSelector(getWarehouseModalShow)
  const pageSize = useSelector(getWarehousePagesize);
  const scopes = useSelector(getScopes)
  const role = useSelector(getRole)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const { t: translate } = useTranslation();

  function onViewClick(warehouse) {
    dispatch(setWarehouse(warehouse));
    dispatch(setWarehouseModalShow(true));
  };

  const onViewHide = (event) => {
    dispatch(setWarehouseModalShow(false));
  };

  const onclickEdit = (warehouse) => {
    dispatch(setWarehouse(warehouse));
    navigate("/warehouse/edit");
  };

  const onclickSyncWMSProducts = () => {
    confirmAlert({
      title: translate('title_confirm_sync_wms'),
      message: translate(`message_confirm_sync_wms`),
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            GetImport({token: token, admin_id: admin_id, location_id: location_id})
            .then()
            .catch(error => {HandleError({ error: error })})
          }
        },
        {
          label: 'No'
        }
      ]
    });


  }

  const onPaginationClick = (event) => {
    dispatch(setWarehousePagination(event.target.value))
  };

  const onProductPageSizeChange = (event) => {
    dispatch(setWarehousePageSize(event.value))
  };

  const onclickAdd = () => {
    navigate("/warehouse/add");
  };

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    },
    pagination: {
      maxbuttons: 5, //uneven number
      onClick: onPaginationClick
    },
    pagesize: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selection",
      pagesize: pageSize,
      selected: { 'id': pageSize, 'name': pageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onProductPageSizeChange
    },

  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.stock >= 1) {
    buttons.add = {
      tag: "Add",
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    }
  }
  if (scopes.stock >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
  }

  // add buttons based on role
  if (role === 'super' || role === 'admin') {
    buttons.sync = {
      tag: 'sync_wms_products',
      value: 'sync_wms_products',
      onClick: onclickSyncWMSProducts
    }
  }

  return buttons
}

