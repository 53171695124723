import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav } from 'react-bootstrap';

import CreateField from '../helpers/createField';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getRole } from '../redux/dataSlices/tokenSlice';
import { printerTypeChoices } from '../selectfieldchoices/kitchenmanagerselect.mjs';


export default function KmPrinterForm(props) {
  const { t: translate } = useTranslation();
  const role = useSelector(getRole);

  if (props.data.hasOwnProperty('location_id')) {
    if (props.location_id.hash) {
      props.location_id.selected = { 'value': props.data.location_id, 'label': props.location_id.hash[props.data.location_id]?.name }
    }
  }

  if (props.data.hasOwnProperty('admin_id')) {
    if (props.admin_id.hash) {
      props.admin_id.selected = { 'value': props.data.admin_id, 'label': props.admin_id.hash[props.data.admin_id]?.name }
    }
  }


  if (props.data.hasOwnProperty('type')) {
    props.type.selected = { 'value': props.data.type, 'label': printerTypeChoices.find(type => type.id === props?.data?.type)?.name }

    if (props.data.type.includes('Epson')) {
      props.fields.find(field => field.id === 'dots_per_line').type = 'hidden';
      props.fields.find(field => field.id === 'dots_per_line').required = false;
      props.fields.find(field => field.id === 'device_id').type = 'text';
      props.fields.find(field => field.id === 'device_id').required = true;
    }
    
    if (props.data.type.includes('Sunmi')) {
      if (role === 'super' || role === 'admin') props.fields.find(field => field.id === 'dots_per_line').type = 'number';
      if (role !== 'super' && role !== 'admin') props.fields.find(field => field.id === 'dots_per_line').type = 'hidden';
      props.fields.find(field => field.id === 'dots_per_line').required = false;
      props.fields.find(field => field.id === 'device_id').type = 'hidden';
      props.fields.find(field => field.id === 'device_id').required = false;

      if (role !== 'super' && role !== 'admin') {
        switch (props.data.type) {
          case "Sunmi_NT311":
          case "Sunmi_NT311S":
            props.data.dots_per_line = 384;
            break;
          default:
            props.data.dots_per_line = 0;
            break;
        }
      }

    }
  }

  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  props.fields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        objectFields.push(CreateField(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    } else {
      basicFields.push(CreateField(field, props))
    }
  });

  return (
    <>
      <Form onSubmit={props.submit.onClick}>
        <Tab.Container defaultActiveKey="main">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
        <Button variant="primary" type={props.cancel.type} onClick={props.cancel.onClick}>{translate(props.cancel.title)}</Button>
        <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>{translate(props.submit.title)}</Button>
        <p className='form-required-text-hint'>{translate("Fields with * are required")}</p>
      </Form>
    </>
  );
}