import { useRef, useState } from "react";
import PostProductSearch from "../../actablueAPI/products/PostProductsSearch";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { getRole, getToken } from "../../redux/dataSlices/tokenSlice"
import { useSelector } from "react-redux";

export const SettingsFormFields = () => {
  const role = useSelector(getRole)
  const scopes = useSelector(getScopes);
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const [controller, setController] = useState();
  const signal = useRef();

  const asyncSelectLoadOptions = (inputValue) => {
    if (controller) controller.abort();
    let newController = new AbortController();
    setController(newController);
    signal.current = newController?.signal;

    const searchDataObject = {
      'text': inputValue,
      'fuzziness': 2,
      'type': 'article',
      'fields': ['name', 'skuCode'],
      'limit': 25
    };

    // Return the promise here
    return new Promise((resolve, reject) => {
      if (inputValue.length === 0) {
        resolve([]);
        return;
      }

      PostProductSearch({ token: token, admin_id: admin_id, location_id: location_id, data: searchDataObject, signal: signal.current })
        .then(response => {
          let options = []
          if (response.data) {
            // eslint-disable-next-line
            response.data.map((option) => {
              options.push({ 'value': option.id, 'label': option.name || option.id })
            })
          }
          resolve(options);  // Resolve with response data which should be an array of options
        })
        .catch(error => {
          console.error('Error fetching options:', error);
          reject(error);  // Reject the promise in case of error
        });
    });
  };

  const settingsProductsFormFields =
    [
      { type: 'hidden', name: 'id', id: 'id', readonly: true },
      { type: 'select', name: 'Master product', id: 'master_product', formdatafield: 'master_product', md: 3 },
      { type: 'hidden', name: 'Sync active', id: 'sync_active', readonly: true, md: 12 },
      { type: 'hidden', name: 'Replace eans', id: 'replace_eans', readonly: true, md: 12 },
    ]

  if (role === 'super' || role === 'admin') {
    settingsProductsFormFields.splice(2, 1, // overwrite index 2 
      { type: 'checkbox', name: 'Sync active', id: 'sync_active', md: 12 },
    )
    settingsProductsFormFields.splice(3, 1, // overwrite index 3   
      { type: 'checkbox', name: 'Replace eans', id: 'replace_eans', md: 12 },
    )
  }

  const settingsFormFields =
    [
      { type: 'object', name: 'Products', id: 'products', fields: settingsProductsFormFields }
    ]


  if (scopes.schapkaartjes) {
    const settingsSchapkaartjesFormFields =
      [
        { type: 'hidden', name: 'admin_id', id: 'admin_id', readonly: true },
        { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
        { type: 'select', name: 'Code type on template', id: 'code_to_print', formdatafield: 'code_to_print' },
        { type: 'select', name: 'Default printer', id: 'default_printer', formdatafield: 'default_printer' },
        { type: 'select', name: 'Default print template', id: 'print_default_template', formdatafield: 'print_default_template' },
        { type: 'select', name: 'Default ESL template', id: 'esl_default_template', formdatafield: 'esl_default_template' },
        { type: 'select', name: 'Accepted currency on template', id: 'accepted_currency', formdatafield: 'accepted_currency' },
        { type: 'checkbox', name: 'Inverted print', id: 'inverted_print', md: 12 },
      ]

    settingsFormFields.push({ type: 'object', name: 'Shelflabels', id: 'schapkaartjes', fields: settingsSchapkaartjesFormFields })
  }

  if (scopes.cashcount) {
    const settingsCashcountFormFields =
      [
        { type: 'hidden', name: 'admin_id', id: 'admin_id', readonly: true },
        { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
        { type: 'multi+create', name: 'Accepted currency', id: 'allowed_currency', formdatafield: 'allowed_currency', required: true, md: 6 },
      ]

    settingsFormFields.push({ type: 'object', name: 'Cashcount', id: 'cash-count', fields: settingsCashcountFormFields })
  }

  if (scopes.sortcategories) {
    const kitchenManagerSettingsFormFields =
      [
        { type: 'hidden', name: 'admin_id', id: 'admin_id', readonly: true },
        { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
        { type: 'checkbox', name: 'Enabled', id: 'kitchen_manager_enabled', md: 12 },
        { type: 'select', name: 'Default printer', id: 'km_default_printer', formdatafield: 'km_default_printer', required: true, md: 6 },
        { type: 'select', name: 'Default screen', id: 'default_screen', formdatafield: 'default_screen', required: true, md: 6 },
        { type: 'async-select', name: 'On Hold Product', id: 'on_hold_product', formdatafield: 'on_hold_product', required: true, loadOptions: asyncSelectLoadOptions, md: 3 },
        { type: 'async-select', name: 'Next Course Product', id: 'next_course_product', formdatafield: 'next_course_product', required: true, loadOptions: asyncSelectLoadOptions, md: 3 },
        { type: 'async-select', name: 'VIP Product', id: 'vip_product', formdatafield: 'vip_product', required: true, loadOptions: asyncSelectLoadOptions, md: 3 },
        { type: 'async-select', name: 'Pause Product', id: 'pause_product', formdatafield: 'pause_product', required: true, loadOptions: asyncSelectLoadOptions, md: 3 },
      ]

    settingsFormFields.push({ type: 'object', name: 'Kitchen manager', id: 'kitchenmanager', fields: kitchenManagerSettingsFormFields })
  }

  return settingsFormFields
}