import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { getScopes } from "../../redux/dataSlices/scopesSlice";

import { pagesize } from "../../selectfieldchoices/pagesize.mjs";
import { getScreenModalShow, getScreenPageSize, getScreenPagination, setScreen, setScreenModalShow, setScreenPagesize, setScreenPagination, setScreens } from "../../redux/dataSlices/kitchenmanagerSlice";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setError, setResponseError } from "../../redux/dataSlices/errorSlice";
import DeleteScreen from "../../actablueAPI/kitchenmanager/DeleteScreen";
import GetScreens from "../../actablueAPI/kitchenmanager/GetScreens";
import useHandleError from "../../customhooks/useHandleError";

export default function ScreenButtons() {
  const modalShow = useSelector(getScreenModalShow);
  const pageSize = useSelector(getScreenPageSize);
  const scopes = useSelector(getScopes);
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id)
  const printer_pagination = useSelector(getScreenPagination);
  const printer_pagesize = useSelector(getScreenPageSize);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const HandleError = useHandleError();

  function onViewClick(screen) {
    dispatch(setScreen(screen));
    dispatch(setScreenModalShow(true));
  };

  const onViewHide = () => {
    dispatch(setScreenModalShow(false));
  };

  const onClickEdit = (event) => {
    dispatch(setScreen(event));
    navigate("/kitchenmanager/screen/edit");
  };

  const onPaginationClick = (event) => {
    dispatch(setScreenPagination(event.target.value))
  };

  const onPageSizeChange = (event) => {
    dispatch(setScreenPagesize(event.value))
  }

  const onClickAdd = () => {
    navigate("/kitchenmanager/screen/add");
  };

  function onClickDelete(screen) {
    confirmAlert({
      title: translate('Confirm to remove'),
      message: translate('remove_confirm', { object: `"${screen?.name}"` }),
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            dispatch(setLoading(true))
            // check token
            DeleteScreen({ token: token, id: screen?.id, admin_id: admin_id, location_id: location_id })
              .then(() => {
                GetScreens({ token: token, admin_id: admin_id, location_id: location_id, page: printer_pagination, size: printer_pagesize })
                  .then(response => {
                    dispatch(setScreens(response.data))
                  })
                  .catch(error => {
                    HandleError({ error: error })
                    dispatch(setLoading(false))
                  })
              })
              .catch(error => {
                if (error.response) { dispatch(setResponseError(JSON.stringify(error.response))) }
                if (!error.response) { dispatch(setError(JSON.stringify(error))) }
              })
              .finally(() => {
                dispatch(setLoading(false))
              })
          }
        },
        {
          label: 'No',
        }
      ]
    });
  };

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    },
    pagination: {
      maxbuttons: 5, //uneven number
      onClick: onPaginationClick
    },
    pagesize: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selection",
      pagesize: pageSize,
      selected: { 'id': pageSize, 'name': pageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onPageSizeChange
    },

  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.screens >= 1) {
    buttons.add = {
      tag: "Add",
      value: 'add',
      onClick: onClickAdd,
      className: 'add-button'
    }
  }
  if (scopes.screens >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onClickEdit
    }
  }
  if (scopes.screens >= 3) {
    buttons.delete = {
      tag: "Delete",
      value: 'delete',
      onClick: onClickDelete
    }
  }

  return buttons
}

