import { useNavigate } from "react-router-dom";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { useSelector, useDispatch } from "react-redux";
import { setCategory, getCategoryModalShow, setCategoryModalShow, getSelectedProductCategories, setSelectedProductCategories, setCategoryProducts } from "../../redux/dataSlices/categorySlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import PutCategory from "../../actablueAPI/products/PutCategory";
import GetCategoryByIdProducts from "../../actablueAPI/products/GetCategoryByIDProducts";
import useHandleError from "../../customhooks/useHandleError";

export default function CategoryButtons() {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const modalShow = useSelector(getCategoryModalShow)
  const scopes = useSelector(getScopes)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const selectedCategories = useSelector(getSelectedProductCategories)

  function onViewClick(event, productcategory) {
    event.stopPropagation();
    dispatch(setCategory(productcategory));
    GetCategoryByIdProducts({token:token, id:productcategory.id, admin_id:admin_id})
    .then(response => {
      dispatch(setCategoryProducts(response.data))
      dispatch(setCategoryModalShow(true));
    })
    .catch(error => {
      dispatch(setCategoryModalShow(true));
    })
  };

  const onToggleIsVisible = (category, refresh) => {
    let newCategory = JSON.parse(JSON.stringify(category))
    newCategory.is_visible = !category.is_visible
    PutCategory({ token: token, admin_id: admin_id, category: newCategory })
    // .then(response => {
    // })
    .catch(error => {
      HandleError({ error: error })
    })
    .finally(() => {
      refresh()
    })
  }

  const onViewHide = (event) => {
    event.stopPropagation();
    dispatch(setCategoryModalShow(false));
  };

  const onclickEdit = (event, productcategory) => {
    event.stopPropagation();
    dispatch(setCategory(productcategory));
    navigate("/productcategory/edit");
  };

  const onclickAdd = () => {
    navigate("/productcategory/add");
  };

  const onSelect = (event, category) => {
    event.stopPropagation();
    if (selectedCategories?.hasOwnProperty(category.id)) {
      const newSelectedCategories = { ...selectedCategories };
      delete newSelectedCategories[category.id];
      dispatch(setSelectedProductCategories(newSelectedCategories));
    } else {
      const newSelectedCategories = { ...selectedCategories, [category.id]: category };
      dispatch(setSelectedProductCategories(newSelectedCategories));
    }
  };

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    },
    select: {
      onClick: onSelect
    },
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.productcategories >= 1) {
    buttons.add = {
      tag: "Add",
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    }
  }
  if (scopes.productcategories >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
    buttons.multiEdit = {
      tag: "Edit",
      value: 'multiEdit',
      onClick: onclickEdit
    }
    buttons.toggleIsVisible = {
      tag: "ToggleIsVisible",
      value: 'toggleIsVisible',
      onClick: onToggleIsVisible
    }
  }
  // if (scopes.productcategories >= 3) {
  //   buttons.del = {
  //     tag:'Delete',
  //     value:'del',
  //     onClick:onclickDel
  //   }
  // }

  return buttons
}

