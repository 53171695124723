import { useSelector } from "react-redux"
import { getRole } from "../../redux/dataSlices/tokenSlice"

export const ScreenFormFields = () => {
  const role = useSelector(getRole);

  const order_sequence = [
    { type: 'select', name: `New Order`, id: 'new_order', formdatafield: 'new_order', prefix: 'order_sequence' },
    { type: 'select', name: `Next Course`, id: 'next_course', formdatafield: 'next_course', prefix: 'order_sequence' },
    { type: 'select', name: `VIP`, id: 'vip', formdatafield: 'vip', prefix: 'order_sequence' },
    { type: 'select', name: `Pause`, id: 'pause', formdatafield: 'pause', prefix: 'order_sequence' },
    { type: 'select', name: `On Hold`, id: 'on_hold', formdatafield: 'on_hold', prefix: 'order_sequence' },
  ]

  const settings = [
    { type: 'select', name: `Next Device (Ready)`, id: 'next_device_ready', formdatafield: 'next_device_ready', prefix: 'settings' },
    { type: 'number', name: `Amount of Rows`, id: 'amount_rows', prefix: 'settings', required: true },
    { type: 'number', name: `Amount of Columns`, id: 'amount_cols', prefix: 'settings', required: true },
    { type: 'object', name: 'Order Sequence', id: 'order_sequence', fields: order_sequence }
  ]

  const screenFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'admin_id', id: 'admin_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'text', name: 'Name', id: 'name', required: true },
    { type: 'select', name: 'Type', id: 'type', formdatafield: 'type', required: true },
    { type: 'checkbox', name: 'Active', id: 'active' },
    { type: 'object', name: 'Settings', id: 'settings', fields: settings },
  ]

  if (role === 'super' || role === 'admin') {
    screenFormFields.splice(1, 1, // overwrite index 1 
      { type: 'select', name: 'Administration', id: 'admin_id', formdatafield: 'admin_id', required: true },
    )
    screenFormFields.splice(2, 1, // overwrite index 2 
      { type: 'select', name: 'Location', id: 'location_id', formdatafield: 'location_id', required: true },
    )
  }

  return screenFormFields
}