import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getProducts, setProducts, getProductPagesize, getProductPagination, getSelectedProducts, getProductFilter, getProductSearch, setProductSearch, setProductFilter } from "../../redux/dataSlices/productSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { getTemplatesAll, setTemplatesAll } from "../../redux/dataSlices/templateSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import BasicCard from "../../components/card"
import ProductTable from "../../components/productTable";
import SearchAndFilter from "../../components/searchAndFilter";
import { productFilterChoices } from "../../selectfieldchoices/productselect.mjs";
import { getSettingsSchapkaartjes, setSettingsSchapkaartjes } from "../../redux/dataSlices/settingsSlice";
import { getProgressModalShow, setProgressModalShow } from "../../redux/dataSlices/analyseSlice";
import ProgressModal from "../../components/progressModal";
import GetAllTemplates from "../../actablueAPI/shelflabels/GetAllTemplates";
import GetAllPrinters from "../../actablueAPI/shelflabels/GetAllPrinters";
import { setPrintersAll } from "../../redux/dataSlices/printerSlice";
import GetAllCategoriesWithType from "../../actablueAPI/products/getAllCategoriesWithType";
import { getHashJournalCategoriesAll, getJournalCategoriesAll, setJournalCategoriesAll } from "../../redux/dataSlices/categorySlice";
import { getHashVatsAll, getVatsAll, setVatsAll } from "../../redux/dataSlices/vatSlice";
import GetAllVats from "../../helpers/getAllVats";
import GetProductsType from "../../actablueAPI/products/GetProductsType";
import PostProductSearch from "../../actablueAPI/products/PostProductsSearch";
import GetProductScanLive from "../../actablueAPI/products/GetProductsScanLive";
import GetSettings from "../../actablueAPI/shelflabels/GetSettings";
import { MultiEditFormData } from "../../helpers/multiEditProducts/multiEditFormData";
import useHandleError from "../../customhooks/useHandleError";

const Products = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const journalCategories = useSelector(getJournalCategoriesAll)
  const hash_journal_categories = useSelector(getHashJournalCategoriesAll)
  const vats_all = useSelector(getVatsAll)
  const hash_vats_all = useSelector(getHashVatsAll)
  const products = useSelector(getProducts);
  const selectedProducts = useSelector(getSelectedProducts)
  const templates = useSelector(getTemplatesAll);
  const pagination = useSelector(getProductPagination)
  const pageSize = useSelector(getProductPagesize)
  const settings = useSelector(getSettingsSchapkaartjes)
  const scopes = useSelector(getScopes)
  const modal_show = useSelector(getProgressModalShow)
  const filter = useSelector(getProductFilter)
  const search = useSelector(getProductSearch)
  const HandleError = useHandleError();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false)
  const [multiEditData, setMultiEditData] = useState({})
  const navTitle = "Products"

  function toggleRefresh() {
    setRefresh(!refresh)
  }

  const onSearchChange = (event) => {
    dispatch(setProductSearch(event.target.value))
  }

  const onSearchKeyDown = (key) => {
    if (key.keyCode === 13) {
      onSearchClick()
    }
  }

  const onSearchClick = () => {
    if (search !== '') {
      const searchDataObject = {
        'text': search,
        'fuzziness': 0,
        'type': filter,
        'fields': ['name', 'skuCode'],
        'limit': 25
      }
      axios.all([
        PostProductSearch({token:token, admin_id:admin_id, location_id:location_id, data:searchDataObject}),
        GetProductScanLive({token:token, admin_id:admin_id, location_id:location_id, ean:search})
      ])
      .then(axios.spread((searchResponse, scanResponse) => {
        let newProducts = { 'content': [] }
        newProducts.content = [...searchResponse.data, ...scanResponse.data]
        dispatch(setProducts(newProducts))
      }))
      .catch(error => {HandleError({ error: error })})
      .finally (() => {dispatch(setLoading(false))})
    }
    if (search === '') {
      GetProductsWithFilter()
    }
  }

  const onFilterChange = (event) => {
    if (event) {
      dispatch(setProductFilter(event.value))
    } else {
      dispatch(setProductFilter('article'))
    }
  }

  const searchAndFilter = {
    search: {
      value: search,
      onChange: onSearchChange,
      onClick: onSearchClick,
      onKeyDown: onSearchKeyDown,
    },
    filter: {
      selected: { 'id': filter, 'name': filter },
      options: productFilterChoices,
      onChange: onFilterChange,
      clearable: Boolean(false)
    }
  }

  const multiEditFormData = MultiEditFormData({
    token:token,
    admin_id: admin_id,
    journalCategories:journalCategories,
    hash_journal_categories:hash_journal_categories,
    vats_all: vats_all,
    hash_vats_all: hash_vats_all,
    selectedProducts: selectedProducts,
    multiEditData: multiEditData,
    dispatch:dispatch,
    setMultiEditData: setMultiEditData,
    toggleRefresh: toggleRefresh
  })


  const productsCard = {
    size: 12,
    title: navTitle,
    data: <ProductTable admin_id={admin_id} location_id={location_id} products={products} templates={templates} settings={settings} multiEdit={multiEditFormData} refresh={toggleRefresh} />,
  };

  const onViewHide = () => {
    dispatch(setProgressModalShow(false));
  };

  const progressModal = {
    modal: {
      show: modal_show,
      title: 'Processing...',
      onHide: onViewHide
    }
  }

  const GetProductsWithFilter = () => {
    dispatch(setLoading(true))
    GetProductsType({token:token, admin_id:admin_id, location_id:location_id, page:pagination, size:pageSize, filter:filter})
    .then(response => {dispatch(setProducts(response.data))})
    .catch(error => {HandleError({ error: error })})
    .finally(() => {dispatch(setLoading(false))})
  }

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (admin_id) {
      getDataFromApi()
      dispatch(setLoading(true))
      onSearchClick()
    }
    // eslint-disable-next-line
  }, [admin_id, location_id, filter, pagination, pageSize, refresh]);

async function getDataFromApi() {
  if (!journalCategories || journalCategories?.length <= 0) {
    await GetAllCategoriesWithType({ type: 'JOURNAL', token: token, admin_id: admin_id, location_id: location_id })
    .then(async (response) => {dispatch(setJournalCategoriesAll(response))})
  }
  
  if (!vats_all || vats_all?.length <= 0) {
    await GetAllVats({ token: token, admin_id: admin_id, location_id: location_id })
    .then(async (response) => {dispatch(setVatsAll(response))})
  }

  if (location_id && scopes.schapkaartjes) {
    await GetAllTemplates({ token: token, admin_id: admin_id, location_id: location_id, base_labels: true, sorted: true, page: 1, size: 25 })
    .then(response => {dispatch(setTemplatesAll(response))})
    .catch(error => {HandleError({ error: error })})

    await GetAllPrinters({ token: token, admin_id: admin_id, location_id: location_id })
    .then(response => {dispatch(setPrintersAll(response))})
    .catch(error => {HandleError({ error: error })})

    await GetSettings({token:token, admin_id:admin_id, location_id:location_id})
    .then(response => {dispatch(setSettingsSchapkaartjes(response.data))})
    .catch(error => {HandleError({ error: error })})
  }
}

  return (
    <>
      <ProgressModal {...progressModal} />
      {location_id && <SearchAndFilter {...searchAndFilter} />}
      <BasicCard {...productsCard} />
    </>
  );
};
export default Products;