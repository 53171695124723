import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { getKeycloakUsersAll, setKeycloakUsersAll, setFilter, setSearch, getFilter, getSearch } from "../../redux/dataSlices/keycloakSlice";
import BasicCard from "../../components/card"
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import KeycloakUserTable from "../../components/keycloakUserTable";
import GetAllKeycloakUsers from "../../actablueAPI/keycloak/getAllKeycloakUsers";
import { filterChoices } from "../../selectfieldchoices/keycloakselect.mjs";
import SearchNoButtonAndFilterSingleSelect from "../../components/searchNoButtonAndFilterSingleSelectField";
import useHandleError from "../../customhooks/useHandleError";

const KeycloakUsers = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const keycloak_users = useSelector(getKeycloakUsersAll);
  const filter = useSelector(getFilter)
  const search = useSelector(getSearch)
  const dispatch = useDispatch();
  const [filteredUsers, setFilteredUsers] = useState()
  const [refresh, setRefresh] = useState(false)
  const HandleError = useHandleError();

  function toggleRefresh() {
    setRefresh(!refresh)
  }
  
  const onSearchChange = (event) => {
    let value = undefined
    if (event) {
      value = event.target.value
    }
    dispatch(setSearch(value))
    SearchAndFilter(filter, value)
  }

  const onFilterChange = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    dispatch(setFilter(value))
    SearchAndFilter(value, search)
  }

  const searchAndFilter = {
    search: {
      value: search,
      onChange: onSearchChange,
    },
    filter: {
      label: '',
      options: filterChoices,
      hash: '',
      onChange: onFilterChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    }
  }

  function SearchAndFilter(filter, search) {
    if (!filter && !search) {      
      return setFilteredUsers()
    }

    let filtered = keycloak_users // if using a switch statement, set it to []

    if (filter) {
      filtered = (keycloak_users.filter(user => user.attributes?.origin?.includes(filter)))   
    }

    if (search) {
      return setFilteredUsers(filtered.filter(categorie => JSON.stringify(categorie).toLowerCase().includes(search.toLowerCase())))
    }
    return setFilteredUsers(filtered)
  }

  const card = {
    size: 12,
    title: `Keycloak users (${filteredUsers?.length || keycloak_users?.length || 0})` ,
    data: <KeycloakUserTable users={filteredUsers || keycloak_users} refresh={toggleRefresh}/>,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Keycloak users"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    GetAllKeycloakUsers({token:token})
    .then(((keycloakUserResponse) => {
      dispatch(setKeycloakUsersAll(keycloakUserResponse))
      SearchAndFilter(filter, search)
      dispatch(setLoading(false))
    }))
    .catch(error => {
      HandleError({ error: error })
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, [admin_id, refresh]);

  return (
    <>
      <SearchNoButtonAndFilterSingleSelect {...searchAndFilter} />
      <BasicCard {...card} />
    </>
  )
};
export default KeycloakUsers;